import { Component, Input } from '@angular/core'

@Component({
  selector: 'svi-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
  @Input() photoUrl
  @Input() rank
  @Input() title
  @Input() description
  @Input() matchingValue
  @Input() disabled
}
