<svi-navbar></svi-navbar>
<svi-page-title i18n>
  Contact
</svi-page-title>
<svi-page-content class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

    <span class="validation-error" *ngIf="form.hasError('required', ['name']) && clicked" i18n>Name required</span>
    <input type="text" required class="form-control" placeholder="Name" i18n-placeholder formControlName="name">

    <span class="validation-error" *ngIf="form.hasError('required', ['email']) && clicked" i18n>E-Mail required</span>
    <span class="validation-error" *ngIf="form.hasError('email', ['email']) && clicked" i18n>Invalid E-Mail</span>
    <input type="email" required email class="form-control" placeholder="E-Mail" i18n-placeholder formControlName="email">

    <span class="validation-error" *ngIf="form.hasError('required', ['message']) && clicked" i18n>Message required</span>
    <textarea required class="form-control" placeholder="Message" i18n-placeholder formControlName="message"></textarea>

    <div class="button-wrapper">
      <button type="submit" [style.background-color]="getButtonColor()" class="button button-primary submit-button">
        <ng-container *ngIf="sendState===undefined" i18n>Send</ng-container>
        <ng-container *ngIf="sendState===true" i18n>E-Mail sent</ng-container>
        <ng-container *ngIf="sendState===false" i18n>Error. Please try again later.</ng-container>
      </button>
    </div>



  </form>
  <h3 class="address-title" i18n>Contact addresses</h3>
  <div class="address-block">
    <div i18n>Université du Luxembourg</div>
    <div i18n>FLSHASE-IPSE</div>
    <div i18n>Programme gouvernance européenne</div>
    <div i18n>Maison des Sciences Humaines</div>
    <div i18n>Address: 11, Porte des Sciences, L-4366 Esch-Belval</div>
    <div>
      <ng-container i18n>E-Mail</ng-container>
      <ng-container>:&nbsp;</ng-container>
      <a href="mailto:info@smartwielen.lu">info@smartwielen.lu</a>
    </div>
  </div>
  <div class="address-block">
    <div i18n>Zentrum fir politesch Bildung</div>
    <div i18n>Address: 28, route de Diekirch, L-7220 Walferdange</div>
    <div i18n>Phone: +352 247 75 272</div>
    <div>
      <ng-container i18n>E-Mail</ng-container>
      <ng-container>:&nbsp;</ng-container>
      <a href="mailto:info@smartwielen.lu">info@smartwielen.lu</a>
    </div>
  </div>
</svi-page-content>