import {
  Component,
  Inject,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ElementRef,
  PLATFORM_ID,
  LOCALE_ID
} from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { isPlatformBrowser } from '@angular/common'
import { LastRouteService } from './last-route.service'

import { Apollo } from 'apollo-angular'
import {
  Election,
  Recommendation,
  RecommendationOptions,
  Candidate,
  Party,
  Matching,
  Responder
} from '@smartvote/common'
import { Position } from '@smartvote/components/src/app/smartmap/smartmap.component'

import { AnswerService } from '../core/answer.service'
import { VoterIdService } from '../core/voter-id.service'
import { LocalStorage } from '../core/tokens'

import { getSmartmapPositions, getSmartmapLegendItems } from './matching.page'

import { GetElectionsQuery, GetRecommendationQuery } from '../../__generated__/types'

const { GetRecommendation, GetElections } = require('graphql-tag/loader!./matching.page.graphql')

@Component({
  selector: 'svi-matching-share-page',
  templateUrl: 'matching-share.page.html',
  styleUrls: ['matching.page.scss', 'matching-share.page.scss']
})
export class MatchingSharePage implements AfterViewInit {
  @ViewChild('translations')
  translationTemplate: TemplateRef<any>
  translations: any
  tabIndex = 0
  elections: Observable<Election[]>
  recommendation: Observable<Recommendation>
  resultSummary = {
    district: '',
    nofSeats: '',
    nofCandidates: ''
    // nofParticipatingCandidates: '' // TODO: Figure out how to get this number
  }
  nofSeats: number
  loading = true
  // Current states
  private _elections: Election[]
  private _recommendation: Recommendation

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private answerService: AnswerService,
    private voterIdService: VoterIdService,
    @Inject(LocalStorage) private _localStorage: Storage,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public localeId: string,
    private lastRouteSrv: LastRouteService
  ) {
    this.elections = this._getElections() as any // TODO: Fix types
    this.recommendation = combineLatest(this.route.queryParams, this.elections).pipe(
      switchMap(([params, elections]) => {
        this._elections = elections
        this.tabIndex = parseInt(params['tab'], 10) || 0
        const recommendationId = params['rid']
        this.loading = true
        if (this._doGetRecommendation(recommendationId)) {
          return this._getRecommendation(recommendationId, 0, -1)
        } else if (this._recommendation) {
          return of(this._recommendation)
        } else {
          return of(null)
        }
      }),
      tap((recommendation: any) => {
        this.loading = false
        if (!recommendation) {
          return
        }
        recommendation.positions = getSmartmapPositions(recommendation).concat({
          id: 'me',
          color: 'red',
          label: this.getTranslatedMessage('my-position'),
          ...recommendation.voter.smartmapPosition
        })
        recommendation.smartmapLegendItems = getSmartmapLegendItems(recommendation)
        recommendation.matchings = recommendation.matchings.map(matching => ({
          ...matching,
          ...this._getListItemLabels(matching)
        }))
        if (!this._recommendation || this._recommendation.id !== recommendation.id) {
          // Keep last state before emiting to avoid infite loop
          this._recommendation = recommendation
          this._updateQueryParams({ rid: recommendation.id })
          this._updateResultSummary(this._elections, recommendation)
          this._updateNofSeats(this._elections, recommendation)
        }
      })
    ) as any
  }

  ngAfterViewInit() {
    this.translations = this.translationTemplate
      .createEmbeddedView({})
      .rootNodes.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.textContent }), {})
  }

  navigateToMethodology() {
    this.router.navigate(['/methodology'], { fragment: 'smartmap' })
  }

  onTabChanged(index: number) {
    this._updateQueryParams({ tab: index })
  }

  private _updateQueryParams(params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }

  private _updateResultSummary(elections, recommendation) {
    if (!(elections && recommendation)) {
      return
    }
    const election = elections.find(e => e.id === recommendation.options.electionId)
    if (!election) {
      return
    }
    const district = election.districts.find(d => d.id === recommendation.options.districtId)
    this.resultSummary = {
      district: district.name,
      nofSeats: `${district.seats}`,
      nofCandidates: `${recommendation.matchings.length}`
    }
  }

  private _updateNofSeats(elections, recommendation) {
    if (!elections) {
      return
    }
    const election = elections.find(e => e.id === recommendation.options.electionId)
    if (!election) {
      return
    }
    const district = election.districts.find(d => d.id === recommendation.options.districtId)
    this.nofSeats = district.seats
  }

  private _getListItemLabels(matching: Matching) {
    let title = ''
    let description = ''
    let matchingValue = matching.matchValue
    if (matching.responderType === 'Candidate') {
      const candidate = matching.responder as Candidate
      if (candidate) {
        title = `${candidate.firstname} ${candidate.lastname}`
        description = candidate.party ? candidate.party.name : ''
        if (candidate.nofAnswers === 0) {
          description += ` (${this.getTranslatedMessage('no-answers')})`
          matchingValue = null
        }
      } else {
        title = this.getTranslatedMessage('no-information')
      }
    } else if (matching.responderType === 'Party') {
      const party = matching.responder as Party
      if (party) {
        title = party.name
      } else {
        title = this.getTranslatedMessage('no-information')
      }
    }
    return { title, description, matchingValue }
  }

  private _getElections() {
    return this.apollo
      .query<GetElectionsQuery>({
        query: GetElections
      })
      .pipe(map(({ data }) => data.elections))
  }

  private _getRecommendation(recommendationId: string, offset: number, limit: number): any {
    return this.apollo
      .query<GetRecommendationQuery>({
        query: GetRecommendation,
        variables: {
          recommendationId,
          offset,
          limit
        }
      })
      .pipe(map(({ data }) => ({ ...data.recommendation })))
  }

  private _doGetRecommendation(rid) {
    return rid && !this._recommendation
  }

  onMatchingSelected(matching: Matching) {
    if (matching.responder) {
      if (matching.responderType === 'Candidate') {
        this.router.navigate(['profile', 'candidate', matching.responder.id], {
          relativeTo: this.route.parent,
          queryParamsHandling: 'preserve'
        })
      } else if (matching.responderType === 'Party') {
        this.router.navigate(['profile', 'party', matching.responder.id], {
          relativeTo: this.route.parent,
          queryParamsHandling: 'preserve'
        })
      }
    }
  }

  navigateToHome() {
    this.router.navigate(['home'])
  }

  onSmartmapItemSelect(itemId) {
    const selectedMatching = this._recommendation.matchings.filter(
      matching => matching.responderId === itemId
    )
    if (selectedMatching.length === 1) {
      this.onMatchingSelected(selectedMatching[0])
    }
  }

  private getTranslatedMessage(msg: string): string {
    return this.translations[msg]
  }
}
