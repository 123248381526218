<header class="svi-header">
  <div class="svi-header-content">
    <a [routerLink]="'/'">
      <svi-logo class="d-none d-sm-block" size="big"></svi-logo>
      <svi-logo class="d-block d-sm-none" size="small"></svi-logo>
    </a>

    <div class="links">
      <div class="languages d-none d-sm-block">
        <ng-container *ngFor="let language of languages">
          <a class="language" [class.active]="isLanguageSelected(language.code)" (click)="setLanguage(language.code)">{{language.labelCode}}</a>
          <span class="separator"></span>
        </ng-container>
      </div>
      <svi-language-menu [languages]='languages'></svi-language-menu>
      <div class="social d-none d-sm-block">
        <i class="fab fa-twitter" (click)="sharingService.shareOnTwitter(sharingInfo)"></i>
        <i class="fab fa-facebook-square" (click)="sharingService.shareOnFacebook(sharingInfo)"></i>
        <a [href]="sharingService.getWhatsappUrl(sharingInfo)" data-action="share/whatsapp/share">
          <i class="fab fa-whatsapp d-inline d-sm-none"></i>
        </a>
        <a [href]="sharingService.getMailUrl(sharingInfo)">
          <i class="fa fa-envelope"></i>
        </a>
      </div>
      <div sviSharingTrigger [sharingInfo]="sharingInfo" class="social d-block d-sm-none">
        <i class="fa fa-share-alt"></i>
      </div>
      <ng-container #sharingInfo="sviSharingInfo" sviSharingInfo title="Luxembourg National Elections" i18n-title description="Luxembourg National Elections. Find candidates / parties that share your opinions"
        i18n-description [imagePath]="'/assets/images/luxembourg_home_sharing_' + localeId + '.png'" [sharePath]="'/' + localeId + '/home'"></ng-container>

    </div>
  </div>

  <div class="diagonal">
    <div class="diagonal-left">
    </div>
    <div class="diagonal-center">
      <svg width="120" height="20">
        <polygon points="0,20 120,0 120,20" />
      </svg>
    </div>
    <div class="diagonal-right">
    </div>
  </div>
</header>