<svi-navbar backLabel="" i18n-backLabel></svi-navbar>
<svi-page-title #title i18n>Search for the profiles of candidates & parties</svi-page-title>
<ng-container *ngIf="election | async as election; else loading">
  <div class="svi-database-page-content">
    <svi-tab-group class="tabs" (selectedTabChanged)="onTabChanged($event)" [(selectedIndex)]="tabIndex">
      <svi-tab label="Candidates" i18n-label>
        <div class="svi-database-tab-content">
          <svi-database-filter-group [state]="filterGroupStateChanges | async" [disabled]="loadingdata" [districts]="election.districts"
            [parties]="election.parties" (search)="onSearch($event)" (reset)="onReset()"></svi-database-filter-group>
          <ng-container *ngIf="showList">
            <svi-list-item *ngFor="let candidate of candidates | async" [photoUrl]="candidate.photoUrl" [title]="candidate.firstname + ' ' + candidate.lastname"
              [description]="candidate.description" (click)="navigateToCandidate(candidate.id)"></svi-list-item>
          </ng-container>
        </div>
      </svi-tab>
      <svi-tab label="Parties" i18n-label>
        <div class="svi-database-tab-content">
          <svi-list-item *ngFor="let party of election.parties" [photoUrl]="party.photoUrl" [title]="party.name" (click)="navigateToParty(party.id)"></svi-list-item>
        </div>
      </svi-tab>
    </svi-tab-group>
  </div>
</ng-container>

<ng-template #loading>
  <svi-page-content>
    <svi-loading></svi-loading>
  </svi-page-content>
</ng-template>

<ng-template #translations>
  <span id="elected" i18n>Elected</span>
  <span id="incumbent" i18n>Incumbent</span>
  <span id="male" i18n>Male</span>
  <span id="female" i18n>Female</span>
  <span id="other" i18n>Other</span>
</ng-template>