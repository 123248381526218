<svi-navbar></svi-navbar>
<svi-page-title i18n>
  Frequently Asked Questions (FAQ)
</svi-page-title>
<svi-page-content>
  <h3 i18n>What is smartwielen?</h3>
  <p i18n>smartwielen is a so-called online voting advice application (VAA). Based on a comprehensive questionnaire on policy
    issues, smartwielen enables voters to compare their own political viewpoints with those of candidates and parties.</p>

  <h3 i18n>How was the questionnaire developed?</h3>
  <p i18n>The main criteria for the selection of questions are:</p>
  <ul>
    <li i18n>they are concerned with the current political issues in Luxembourg and Europe;</li>
    <li i18n>Sie betreffen politische Themen in Europa; > Platzhalter</li>
    <li i18n>they originate from the programs of political parties and/or from consultations with journalists, civil society
      and interests;</li>
    <li i18n>they do not favor one or more political party(s);</li>
    <li i18n>they do not allow for multiple interpretations;</li>
    <li i18n>Sie haben einen allgemeinen oder spezifischen Charakter (eine Mischung beider Arten wird angestrebt); Platzhalter</li>
    <li i18n>they deal with only one subject;</li>
    <li i18n>they fit the design of the smartspider.</li>
  </ul>

  <h3 i18n>How are the candidates’ and parties’ positions determined?</h3>
  <p i18n>The candidates and parties answer the smartwielen questionnaire, which includes 43 questions on a wide range of
    current policy issues, some weeks before the election. By doing so they generate their political profile at smartwielen.lu.</p>

  <h3 i18n>How are the results calculated?</h3>
  <p i18n>On the smartwielen website users answer the exact same questionnaire that candidates and parties have already answered.
    smartwielen compares their answers with those of the candidates or parties by calculating the euclidean distance (the
    straight-line distance between two-points in a multidimensional space). As a result, smartwielen presents to each voter
    a specific ranking of all participating candidates or parties, ranking those candidates or parties with the best matching
    at the top of the list. The more questions a user answers, the more precise their voting advice will be.</p>

  <h3 i18n>How should I interpret the matching score?</h3>
  <p i18n>The results are based on a geometric matching. Therefore, the value cannot be considered directly as a proportion
    of the questions in which a voter is absolutely in agreement with the answers of a candidate. Therefore, a match of 70%
    does not mean that a candidate has given exactly the same answer as the voter for exactly 70% of the questions.</p>

  <h3 i18n>How does weighting answers effect my results?</h3>
  <p i18n>Users have the opportunity to weight their answers, which will be considered when calculating their results. Weighting
    questions enable their results to reflect which issues they care about most or least. If a user gives a question a plus
    sign, then it the answer counts twice in the calculation of the matching results (+ = 2). If a user gives a question
    a minus sign, it counts as if the answer were half as important (- = 0.5).</p>

  <h3 i18n>I filled out smartwielen a few weeks ago, how can I find my old results?</h3>
  <p i18n>The answers that a user gives are saved on the computer's local storage. As long as the user does not delete the
    local storage, the user can revise their results at any time.</p>

  <h3 i18n>How can I interpret the smartspider? How is the smartspider calculated?</h3>
  <p i18n>The smartspider graphic visualizes the strength of the candidate or parties' values and political positions based
    on six thematic axes. For each policy area, a value between 0 and 100 points can be reached. A value of 100 stands for
    strong agreement with the formulated goal of the corresponding axis. A value of 0 means that the formulated goal does
    not agree.</p>

  <h3 i18n>How can I interpret the smartmap? How is the smartmap calculated?</h3>
  <p i18n>The map represents similarities of the political profiles of the candidates or parties. The closer the points marking
    the candidates' positions, the more similar are the candidates' political profiles (i.e., their answers to the smartwielen
    survey). Please note that because of the fixed quadratic shape of the smartmap, inferences from the height or width of
    the figure on the statistical relevance of the two dimensions are not valid.</p>

  <h3 i18n>Why are some candidates and parties not included in the platform? Why are there no answers for some parties and
    candidates?</h3>
  <p i18n>We contacted all parties and candidates (via the parties) ahead of the launching of the tool and asked if they
    were interested in participating. All parties and candidates that agreed are included in the website. However, not all
    parties and candidates have completed and confirmed the questionnaire.</p>

  <h3 i18n>What happens to my answers in the user survey?</h3>
  <p i18n>The answers given in the user survey are anonymous and will not affect the results. They will be used by the University
    of Luxembourg for research purposes. For more information, please contact info@smartwielen.lu.</p>

  <h3 i18n>Is smartwielen associated with any specific political parties, candidates, special interests, etc.?</h3>
  <p i18n>No, all organisations involved in the development and operation of smartwielen (e.g., the University of Luxembourg,
    Zentrum fir politesch Bildung and smartvote/Politools) are non-partisan and non-profit organizations.</p>
</svi-page-content>