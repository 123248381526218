/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./question.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/accordion";
import * as i3 from "../../shared/accordion-item/accordion-item.component.ngfactory";
import * as i4 from "../../shared/accordion-item/accordion-item.component";
import * as i5 from "@angular/cdk/collections";
import * as i6 from "@angular/common";
import * as i7 from "./question.component";
var styles_QuestionnaireQuestionComponent = [i0.styles];
var RenderType_QuestionnaireQuestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionnaireQuestionComponent, data: {} });
export { RenderType_QuestionnaireQuestionComponent as RenderType_QuestionnaireQuestionComponent };
function View_QuestionnaireQuestionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "more-info-placeholder"]], null, null, null, null, null))], null, null); }
function View_QuestionnaireQuestionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "cdk-accordion", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.CdkAccordion, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "svi-accordion-item", [["class", "title-right"], ["title", "M\u00E9i Informatiounen"]], null, null, null, i3.View_AccordionItemComponent_0, i3.RenderType_AccordionItemComponent)), i1.ɵdid(3, 180224, null, 0, i4.AccordionItemComponent, [[3, i2.CdkAccordion], i1.ChangeDetectorRef, i5.UniqueSelectionDispatcher], { expanded: [0, "expanded"], title: [1, "title"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "div", [["class", "info-panel"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoTextExpanded; var currVal_1 = "M\u00E9i Informatiounen"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.question.infoText; _ck(_v, 5, 0, currVal_2); }); }
export function View_QuestionnaireQuestionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { questionTextElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "svi-question-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "category"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["questionText", 1]], null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireQuestionComponent_1)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireQuestionComponent_2)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.question.infoText; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.question.infoText; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question.category.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.question.text; _ck(_v, 5, 0, currVal_1); }); }
export function View_QuestionnaireQuestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-questionnaire-question", [], null, null, null, View_QuestionnaireQuestionComponent_0, RenderType_QuestionnaireQuestionComponent)), i1.ɵdid(1, 573440, null, 0, i7.QuestionnaireQuestionComponent, [], null, null)], null, null); }
var QuestionnaireQuestionComponentNgFactory = i1.ɵccf("svi-questionnaire-question", i7.QuestionnaireQuestionComponent, View_QuestionnaireQuestionComponent_Host_0, { question: "question", questionIndex: "questionIndex" }, {}, []);
export { QuestionnaireQuestionComponentNgFactory as QuestionnaireQuestionComponentNgFactory };
