import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomePage } from './home/home.page'
import { QuestionnairePage } from './questionnaire/questionnaire.page'
import { QuestionsAboutYouPage } from './questions-about-you/questions-about-you.page'
import { MatchingPage } from './matching/matching.page'
import { CandidateDetailsPage } from './responder-details/candidate-details.page'
import { CandidateDetailsSharePage } from './responder-details/candidate-details-share.page'
import { PartyDetailsPage } from './responder-details/party-details.page'
import { PartyDetailsSharePage } from './responder-details/party-details-share.page'
import { DatabasePage } from './database/database.page'
import { MatchingSharePage } from './matching/matching-share.page'

import { AboutUsPage } from './static-pages/about-us/about-us.page'
import { ContactPage } from './static-pages/contact/contact.page'
import { MethodologyPage } from './static-pages/methodology/methodology.page'
import { PartnersPage } from './static-pages/partners/partners.page'
import { TermsAndPrivacyPage } from './static-pages/terms-and-privacy/terms-and-privacy.page'
import { FaqPage } from './static-pages/faq/faq.page'

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomePage },
  {
    path: 'matching',
    children: [
      { path: '', redirectTo: 'questionnaire', pathMatch: 'full' },
      {
        path: 'questionnaire',
        component: QuestionnairePage
      },
      { path: 'questions-about-you', component: QuestionsAboutYouPage },
      { path: 'results', component: MatchingPage },
      { path: 'profile/candidate/:id', component: CandidateDetailsPage },
      { path: 'profile/party/:id', component: PartyDetailsPage }
    ]
  },
  {
    path: 'share',
    children: [
      { path: 'results', component: MatchingSharePage },
      { path: 'profile/candidate/:id', component: CandidateDetailsSharePage },
      { path: 'profile/party/:id', component: PartyDetailsSharePage }
    ]
  },
  {
    path: 'profiles',
    children: [
      { path: '', component: DatabasePage },
      { path: 'candidate/:id', component: CandidateDetailsPage },
      { path: 'party/:id', component: PartyDetailsPage }
    ]
  },
  { path: 'about-us', component: AboutUsPage },
  { path: 'contact', component: ContactPage },
  { path: 'methodology', component: MethodologyPage },
  { path: 'partners', component: PartnersPage },
  { path: 'terms-and-privacy', component: TermsAndPrivacyPage },
  { path: 'faq', component: FaqPage }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
