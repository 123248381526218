import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID
} from '@angular/core'
import { Question, Answer } from '@smartvote/common'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'svi-questionnaire-navigation',
  templateUrl: 'questionnaire-navigation.component.html',
  styleUrls: ['questionnaire-navigation.component.scss']
})
export class QuestionnaireNavigationComponent implements OnChanges {
  @ViewChild('container') container: ElementRef

  @Input()
  set questions(questions: Question[]) {
    this.shortQuestions = questions.map(question => {
      const splitQuestionText = question.text.split(' ')
      let shortQuestionText = ''
      if (splitQuestionText.length <= 10) {
        shortQuestionText = question.text
      } else {
        shortQuestionText = splitQuestionText.slice(0, 10).join(' ') + '...'
      }

      return {
        textshort: shortQuestionText,
        ...question
      }
    })
  }
  @Input() userAnswers: Answer[]
  @Input()
  set questionIndex(questionIndex: string) {
    this._questionIndex = parseInt(questionIndex, 10)
  }
  @Output() navigate = new EventEmitter()
  _questionIndex: number
  _hasAnswer: boolean[]
  shortQuestions

  constructor(@Inject(PLATFORM_ID) private platformId) {}

  ngOnChanges() {
    if (isPlatformBrowser(this.platformId)) {
      // TODO: refactor to get rid of the hacky hardcoded 42
      window.scroll(0, this.container.nativeElement.offsetTop - 42)
    }

    if (this.shortQuestions && this.userAnswers) {
      this._hasAnswer = this.shortQuestions.map(question => {
        const currentUserAnswer = this.userAnswers.filter(
          userAnswer => userAnswer.questionId === question.id
        )
        return currentUserAnswer.length > 0 && currentUserAnswer[0].value > -9
      })
    }
  }

  onNavigate(newQuestionIndex) {
    this.navigate.emit(newQuestionIndex)
  }
}
