<svi-navbar backLabel="Back to questionnaire" i18n-backLabel></svi-navbar>
<svi-page-title i18n>
  Questions About You
</svi-page-title>
<svi-page-content>

  <div class="grid grid-reverse grid-align-top">
    <div class="cell text-right">
      <button class="button button-primary button-large icon-right" (click)="continue({saveForm: false})">
        <ng-container i18n>Continue directly to your results</ng-container>
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
    <div class="cell">
      <p class="hint ">
        <b i18n>Please note</b>:
        <ng-container i18n>your answers will be treated anonymously and will not affect your results</ng-container>
      </p>

    </div>
  </div>

  <form class="svi-questions-about-you-form" [formGroup]="form">
    <div class="section">
      <h3 i18n>Biographical information</h3>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-home"></i>
          <input class="form-control" type="text" placeholder="Postal code" i18n-placeholder formControlName="zip">
        </div>
        <div class="field cell">
          <div class="icon-group">
            <i class="fa fa-male"></i>
            <i class="fa fa-female"></i>
          </div>
          <select class="form-control" formControlName="gender">
            <option value="" i18n>Gender</option>
            <option value="1" i18n>Female</option>
            <option value="2" i18n>Male</option>
            <option value="3" i18n>Other</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-birthday-cake"></i>
          <input class="form-control" type="text" placeholder="Year of birth" i18n-placeholder formControlName="yearOfBirth">
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-university"></i>
          <select class="form-control" formControlName="education">
            <option value="" i18n>Education</option>
            <option value="Primary education" i18n>Primary education</option>
            <option value="Lower secondary education" i18n>Lower secondary education</option>
            <option value="Upper secondary education" i18n>Upper secondary education</option>
            <option value="Post-secondary non-tertiary education" i18n>Post-secondary non-tertiary education</option>
            <option value="Short-cycle tertiary education (bac +2)" i18n>Short-cycle tertiary education (bac +2)</option>
            <option value="Bachelor’s or equivalent level (Bac+3, Licence)" i18n>Bachelor’s or equivalent level (Bac+3, Licence)</option>
            <option value="Master’s or equivalent level" i18n>Master’s or equivalent level</option>
            <option value="Doctoral or equivalent level" i18n>Doctoral or equivalent level</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-flag"></i>
          <select class="form-control" formControlName="nationality">
            <option value="" i18n>Nationality</option>
            <option value="Luxembourgish" i18n>Luxembourgish</option>
            <option value="French" i18n>French</option>
            <option value="German" i18n>German</option>
            <option value="Belgian" i18n>Belgian</option>
            <option value="Portuguese" i18n>Portuguese</option>
            <option value="Italian" i18n>Italian</option>
            <option value="other" i18n>Other</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-briefcase"></i>
          <select class="form-control" formControlName="profession">
            <option value="" i18n>Professional activity</option>
            <option value="private sector" i18n>private sector</option>
            <option value="public sector" i18n>public sector</option>
            <option value="self-employed" i18n>self-employed</option>
            <option value="student" i18n>student</option>
            <option value="unemployed" i18n>unemployed</option>
            <option value="housewife or househusband" i18n>housewife or househusband</option>
            <option value="retired" i18n>retired</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-crown"></i>
          <select class="form-control" formControlName="politicalInterest">
            <option value="" i18n>Political interest</option>
            <option value="Very interested" i18n>Very interested</option>
            <option value="Rather interested" i18n>Rather interested</option>
            <option value="Neutral" i18n>Neutral</option>
            <option value="Rather not interested" i18n>Rather not interested</option>
            <option value="Not at all interested" i18n>Not at all interested</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
      </div>
    </div>


    <div class="section">
      <h3 i18n>Party preference</h3>
      <p class="hint">
        <ng-container i18n>How likely is it that you vote for a candidate from the following parties?</ng-container>
        <br>
        <ng-container i18n>'0' means "not at all likely " and '10' means "very likely”.</ng-container>
      </p>
      <div class="grid grid-gutter" *ngFor="let party of parties | async">
        <div class="cell field party-pref">
          <label>
            {{party.name}}: {{form.controls['party.' + party.id].value}}
            <br>
            <span class="link" [class.invisible]="form.controls['party.' + party.id].pristine" (click)="reset(party.id)" i18n>Reset</span>
          </label>
          <input type="range" min="0" max="10" step="1" formControlName="party.{{party.id}}">
        </div>
      </div>
    </div>

  </form>

  <div class="grid grid-gutter grid-align-bottom section">
    <div class="cell">
      <h3 i18n>Thank you for participating!</h3>
    </div>
    <div class="cell text-right">
      <button class="button button-primary button-large icon-right" (click)="continue({saveForm: true})">
        <ng-container i18n>See results</ng-container>
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>

  </div>

</svi-page-content>