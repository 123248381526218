<div class="svi-layout">
  <svi-header></svi-header>
  <div class="content">
    <router-outlet></router-outlet>
    <div class="sponsors">
      <div class="content partner-logo__container">
        <a href="https://wwwen.uni.lu/" target="_blank" class="partner-logo uni-lux"></a>
        <a href="https://zpb.lu/" target="_blank" class="partner-logo zpb"></a>
        <a href="https://www.smartvote.ch/" target="_blank" class="partner-logo smartvote"></a>
      </div>
    </div>
  </div>
</div>
<svi-footer></svi-footer>