<div class="svi-profile">
  <svi-profile-photo class="photo" [photoUrl]="responder.photoUrl"></svi-profile-photo>
  <div class="content profile-header">
    <div class="name">{{displayName}}</div>
    <div *ngIf="getValue(responder, 'website') || getValue(responder, 'twitter') || getValue(responder, 'facebook') || getValue(responder, 'youtube')"
      class="social">
      <span class="social-links-label d-none d-sm-inline" i18n>Web profiles:</span>
      <div class="social-links">
        <a rel="external" target="_blank" *ngIf="getValue(responder, 'website') as url" [href]="getTrustedUrl(url)">
          <i class="fa fa-globe"></i>
        </a>
        <a rel="external" target="_blank" *ngIf="getValue(responder, 'twitter') as url" [href]="getTrustedUrl(url)">
          <i class="fab fa-twitter"></i>
        </a>
        <a rel="external" target="_blank" *ngIf="getValue(responder, 'facebook') as url" [href]="getTrustedUrl(url)">
          <i class="fab fa-facebook-square"></i>
        </a>
        <a rel="external" target="_blank" *ngIf="getValue(responder, 'youtube') as url" [href]="getTrustedUrl(url)">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="content profile-details">
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="getValue(responder,'statement')" class="svi-statement">
  <span class="profile-detail-label" i18n>Statement:</span>
  <div>{{getValue(responder,"statement")}}</div>
</div>

<svi-tab-group *ngIf="responder.answers.length > 0" class="tabs" (selectedTabChanged)="selectedTabChanged.emit($event)" [(selectedIndex)]="tabIndex">
  <!-- Sharing info -->
  <ng-container #sharingInfo2="sviSharingInfo" sviSharingInfo title="Luxembourg National Election 2018" i18n-title description="Here is my smartspider compared with the smarspider of {{displayName}}"
    i18n-description [imageFromElement]="sharingElement" [sharePath]="sharePath"></ng-container>
  <svi-tab label="Smartspider" i18n-label>
    <div class="button-group">
      <button *ngIf="voter.isMe" sviSharingTrigger [sharingInfo]="sharingInfo2" class="button button-share">
        <i class="fa fa-share"></i>
        <ng-container i18n>Share smartspider</ng-container>
      </button>
    </div>
    <sv-smartspider #sharingElement="sviSharingElement" sviSharingElement [screenshotHeight]="650" class="smartspider" [smartspiders]="voter ? [responder.smartspider, voter.smartspider] : [responder.smartspider]"></sv-smartspider>
    <div class="smartspider-legend">
      <svi-chart-legend-item class="legend-item" label="My smartspider" i18n-label></svi-chart-legend-item>
      <svi-chart-legend-item class="legend-item" [color]="'#' + responderColor" [label]="displayName"></svi-chart-legend-item>
    </div>
    <div class="methodology-link">
      <button class="button button-share icon-left" (click)="navigateToMethodology()">
        <i class="fa fa-question-circle"></i>
        <ng-container i18n>smartspider Methodology</ng-container>
      </button>
    </div>
  </svi-tab>
  <svi-tab label="Questionnaire" i18n-label>
    <div class="answer-option-selection-header">
      <span class="header-item" *ngIf="voter && voter.isMe">
        <i class="fa fa-user-circle"></i>&nbsp;
        <ng-container i18n>Me</ng-container>
      </span>
      <svi-chart-legend-item class="header-item" [color]="'#' + responderColor" [label]="displayName"></svi-chart-legend-item>
    </div>
    <cdk-accordion multi="true">
      <svi-accordion-item *ngFor="let category of categories; first as isFirstCategory" [title]="category" [expanded]="isFirstCategory">
        <svi-question-item class="svi-question-item" [class.active]="i % 2 === 0" *ngFor="let qItem of questionItems[category]; first as isFirst; let i = index;"
          [text]="qItem.text" [comment]="qItem.comment" [index]="qItem.index">
          <svi-answer-option-group class="answer-option-group">
            <svi-answer-option-item [selections]="qItem.answerSelections['100']" label="Yes" i18n-label [showLabel]="isFirst" [color]="'#' + responderColor"></svi-answer-option-item>
            <svi-answer-option-item [selections]="qItem.answerSelections['75']" label="Rather yes" i18n-label [showLabel]="isFirst" [color]="'#' + responderColor"></svi-answer-option-item>
            <svi-answer-option-item [selections]="qItem.answerSelections['25']" label="Rather no" i18n-label [showLabel]="isFirst" [color]="'#' + responderColor"></svi-answer-option-item>
            <svi-answer-option-item [selections]="qItem.answerSelections['0']" label="No" i18n-label [showLabel]="isFirst" [color]="'#' + responderColor"></svi-answer-option-item>
          </svi-answer-option-group>
        </svi-question-item>
      </svi-accordion-item>
    </cdk-accordion>

  </svi-tab>
</svi-tab-group>