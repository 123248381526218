<svi-navbar></svi-navbar>
<svi-page-title i18n>
  Partners
</svi-page-title>
<svi-page-content>
  <section>
    <h3 i18n>Scientific partners</h3>
    <hr>
    <div class="partner">
      <a href="https://wwwfr.uni.lu/flshase" target="_blank" class="logo lshase"></a>
      <div class="name">
        Faculté des Lettres, des Sciences Humaines, des Arts et des Sciences de l'Éducation
      </div>
    </div>
    <hr>
    <div class="partner">
      <a href="https://www.liser.lu/" target="_blank" class="logo liser"></a>
      <div class="name">
        LISER (Luxembourg Institute of Socio-Economic Research)
      </div>
    </div>
    <hr>
  </section>
  <section>
    <h3 i18n>Media partners</h3>
    <hr>
    <div class="partner">
      <a href="http://www.journal.lu" target="_blank" class="logo journal"></a>
      <div class="name">
        Lëtzebuerger Journal<br>
        <i>Politik, Finanzen a Gesellschaft</i>
      </div>
    </div>
    <hr>
    <div class="partner">
      <a href="https://www.wort.lu/@smartwielen" target="_blank" class="logo wort"></a>
      <div class="name">
        Luxemburger Wort
      </div>
    </div>
    <hr>
    <div class="partner">
      <a href="https://www.100komma7.lu/" target="_blank" class="logo radio100komma7"></a>
      <div class="name">
        radio 100,7
      </div>
    </div>
    <hr>
  </section>
</svi-page-content>