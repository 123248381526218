var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { environment } from '../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
var EmailService = /** @class */ (function () {
    function EmailService(http) {
        this.http = http;
    }
    EmailService.prototype.sendEmail = function (options) {
        return this.http
            .post(environment.emailerUrl, __assign({}, options, { from: environment.emailerFromAddress }))
            .toPromise();
    };
    return EmailService;
}());
export { EmailService };
