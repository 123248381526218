<div class="d-none d-sm-block navbar-placeholder"></div>
<svi-page-title i18n>
  Shared Results
</svi-page-title>

<ng-template #hint>
  <div *ngIf="loading" class="empty-matching-list" i18n>
    Loading...
  </div>
</ng-template>

<svi-page-content>
  <div class="page-content-spacer" *ngIf="recommendation | async as recommendation;">
    <p style="margin-top:0px;">
      <ng-container i18n>District</ng-container>: {{resultSummary.district}}
    </p>
    <p *ngIf="recommendation.options.responderType === 'Candidate'" i18n>
      Showing candidates
    </p>
    <p *ngIf="recommendation.options.responderType === 'Party'" i18n>
      Showing parties
    </p>
    <button class="button button-primary icon-right" (click)="navigateToHome()">
      <ng-container i18n>Get your own matching</ng-container>
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
</svi-page-content>


<ng-container *ngIf="recommendation | async as recommendation; else hint">
  <svi-tab-group class="svi-matching-results" (selectedTabChanged)="onTabChanged($event)" [(selectedIndex)]="tabIndex">
    <svi-tab label="Ranking" i18n-label>
      <!-- Sharing Info, set meta
        tags only if this tab is active -->

      <ng-container #sharingInfo="sviSharingInfo" sviSharingInfo [setMetaTags]="tabIndex === 0" title="Luxembourg National Election 2018"
        i18n-title description="Here is my voting advice for the Luxembourg National Election 2018" i18n-description [imageFromElement]="sharingElement"
        [sharePath]="'/' + localeId + '/share/results?tab=0&rid=' + recommendation.id"></ng-container>

      <ng-container *ngTemplateOutlet="resultsActions; context: {sharingInfo: sharingInfo}"></ng-container>
      <div #sharingElement="sviSharingElement" sviSharingElement>
        <svi-list-item *ngFor="let matching of recommendation.matchings; let i = index" [class.lastseat]="recommendation.options.responderType === 'Candidate' && i === nofSeats - 1"
          [class.inactive]="recommendation.options.responderType === 'Candidate' && i > nofSeats - 1" [photoUrl]="matching.responder ? matching.responder.photoUrl : null"
          [rank]="i + 1" [title]="matching.title" [description]="matching.description" [matchingValue]="matching.matchingValue"
          (click)="onMatchingSelected(matching)">
        </svi-list-item>
      </div>
    </svi-tab>
    <svi-tab label="smartmap">
      <!-- Sharing Info, set meta
        tags only if this tab is active -->
      <ng-container #sharingInfo2="sviSharingInfo" sviSharingInfo [setMetaTags]="tabIndex === 1" title="Luxembourg National Election 2018"
        i18n-title description="Here is my smartmap for the Luxembourg National Election 2018" i18n-description [imageFromElement]="sharingElement2"
        [sharePath]="'/' + localeId + '/share/results?tab=1&rid=' + recommendation.id"></ng-container>
      <ng-container *ngTemplateOutlet="resultsActions; context: {sharingInfo: sharingInfo2}"></ng-container>

      <div class="smartmap">
        <ng-container *ngIf="elections | async as elections;">
          <sv-smartmap #sharingElement2="sviSharingElement" sviSharingElement [screenshotHeight]="560" [positions]="recommendation.positions"
            [xAxisLabels]="[elections[0].dimensions[0].pole1, elections[0].dimensions[0].pole2]" [yAxisLabels]="[elections[0].dimensions[1].pole2, elections[0].dimensions[1].pole1]"
            (onSelect)="onSmartmapItemSelect($event)"></sv-smartmap>
        </ng-container>
        <ul class="smartmap-legend">
          <li *ngIf="recommendation.voter">
            <svi-chart-legend-item label="My position" i18n-label color="#66318e"></svi-chart-legend-item>
          </li>
          <li *ngFor="let item of recommendation.smartmapLegendItems">
            <svi-chart-legend-item [label]="item.label" [color]="item.color"></svi-chart-legend-item>
          </li>
        </ul>
        <div class="methodology-link">
          <button class="button button-share icon-left" (click)="navigateToMethodology()">
            <i class="fa fa-question-circle"></i>
            <ng-container i18n>smartmap Methodology</ng-container>
          </button>
        </div>
      </div>
    </svi-tab>
  </svi-tab-group>
  <div class="svi-matching-result-summary">
    <div class="item">
      <ng-container i18n>District</ng-container>: {{resultSummary.district}}
    </div>
    <div class="item">
      <ng-container i18n>No. of seats</ng-container>: {{resultSummary.nofSeats}}
    </div>
    <div class="item">
      <ng-container i18n>No. of candidates</ng-container>: {{resultSummary.nofCandidates}}
    </div>
    <!-- <div class="item">
      <ng-container i18n>No. of participating candidates</ng-container>: {{resultSummary.nofParticipatingCandidates}}
    </div> -->
  </div>
</ng-container>

<ng-template #translations>
  <span id="no-information" i18n>No information</span>
  <span id="no-answers" i18n>No answers</span>
  <span id="my-position" i18n>My position</span>
</ng-template>