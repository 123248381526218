/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./actions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./actions.component";
var styles_QuestionnaireActionsComponent = [i0.styles];
var RenderType_QuestionnaireActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionnaireActionsComponent, data: {} });
export { RenderType_QuestionnaireActionsComponent as RenderType_QuestionnaireActionsComponent };
function View_QuestionnaireActionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Weider"]))], null, null); }
function View_QuestionnaireActionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.doneLabel; _ck(_v, 1, 0, currVal_0); }); }
export function View_QuestionnaireActionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "sv-question-standard-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "button button-large button-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate.emit((0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "d-none d-sm-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Zr\u00E9ck"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "button button-large see-results"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.done.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "button", [["class", "button button-large button-primary icon-right"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isLastQuestion ? _co.done.emit() : _co.navigate.emit(1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "span", [["class", "d-none d-sm-inline"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireActionsComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireActionsComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.isLastQuestion; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.isLastQuestion; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFirstQuestion; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.doneDisabled || _co.isLastQuestion); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.doneLabel; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.doneDisabled && _co.isLastQuestion); _ck(_v, 7, 0, currVal_3); }); }
export function View_QuestionnaireActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-questionnaire-actions", [], null, null, null, View_QuestionnaireActionsComponent_0, RenderType_QuestionnaireActionsComponent)), i1.ɵdid(1, 49152, null, 0, i3.QuestionnaireActionsComponent, [], null, null)], null, null); }
var QuestionnaireActionsComponentNgFactory = i1.ɵccf("svi-questionnaire-actions", i3.QuestionnaireActionsComponent, View_QuestionnaireActionsComponent_Host_0, { questions: "questions", questionIndex: "questionIndex", doneDisabled: "doneDisabled", doneLabel: "doneLabel" }, { navigate: "navigate", done: "done" }, []);
export { QuestionnaireActionsComponentNgFactory as QuestionnaireActionsComponentNgFactory };
