import { AnswerOptionSelectionType } from './answer-option-item.component'
import { Answer, Question } from '@smartvote/common'
import { keyBy } from 'lodash'

export interface QuestionItem {
  index: number
  text: string
  category: string
  comment: string
  answerSelections: {
    [answerOptionValue: string]: AnswerOptionSelectionType[]
  }
}

export function getAnswerSelections(refValue, candidateValue, voterValue) {
  const result: AnswerOptionSelectionType[] = []
  if (refValue === candidateValue) {
    result.push('candidate')
  }
  if (refValue === voterValue) {
    result.push('voter')
  }
  return result
}

export function getQuestionItems(
  questions: Question[],
  candidateAnswers: Answer[],
  voterAnswers: Answer[] = [],
  prevQuestions: number = 0
) {
  const _candidateAnswers = keyBy(candidateAnswers, a => a.questionId)
  const _voterAnswers = keyBy(voterAnswers, a => a.questionId)
  return questions.map((q, index) => {
    const ca = _candidateAnswers[q.id] || { value: -9, comment: '' }
    const va = _voterAnswers[q.id] || { value: -9, comment: '' }
    return {
      index: index + prevQuestions,
      text: q.text,
      category: q.category ? q.category.name : '',
      comment: ca.comment,
      answerSelections: {
        '100': getAnswerSelections(100, ca.value, va.value),
        '75': getAnswerSelections(75, ca.value, va.value),
        '25': getAnswerSelections(25, ca.value, va.value),
        '0': getAnswerSelections(0, ca.value, va.value)
      }
    }
  })
}
