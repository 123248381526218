<svi-navbar></svi-navbar>
<svi-page-title i18n>
  About us
</svi-page-title>
<svi-page-content>
  <section>
    <h3 i18n>Who we are</h3>
    <p i18n>smartwielen is a project of the University of Luxembourg in collaboration with the Zentrum fir politesch Bildung.
      The smartwielen website is an adapted version of smartvote, a so-called VAA (Voting Advice Application) that was first
      developed in Switzerland in 2003 and is operated by the politically neutral, non-profit organization Politools.
    </p>
  </section>
  <hr>
  <section class="institution">
    <div class="header">
      <div class="logo uni-lux"></div>
      <div class="details">
        <div class="title">
          <h4 i18n>University of Luxembourg</h4>
        </div>
        <div class="members">
          <ul>
            <li>
              <span class="name">Raphaël Kies</span>
              <span class="function" i18n>Researcher in Politics</span>
            </li>
            <li>
              <span class="name">Dan Schmit</span>
              <span class="function" i18n>Doctoral Candidate in Politics</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p i18n>
      The University of Luxembourg is a public research and higher education institution based in Luxembourg. The university consists
      of three faculties and three interdisciplinary centres.
    </p>
    <p i18n>
      The Faculty of Language and Literature, Humanities, Arts and Education (FLHASE) is specialised in research in the social
      sciences and humanities. It consists of three research units. The politcal scientists involved in the smartwielen project
      are associated with the political governance programme in the “Identités, Politiques, Sociétés, Espaces” (IPSE) research
      unit.
    </p>
    <div>
      <a href="https://wwwen.uni.lu/" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>University of Luxembourg</ng-container>
      </a>
    </div>
  </section>
  <hr>
  <section class="institution">
    <div class="header">
      <div class="logo zpb"></div>
      <div class="details">
        <div class="title">
          <h4 i18n>Zentrum fir politisch Bildung</h4>
        </div>
        <div class="members">
          <ul>
            <li>
              <span class="name">Marc Schoentgen</span>
              <span class="function" i18n>Director</span>
            </li>
            <li>
              <span class="name">Michele Schilt</span>
              <span class="function" i18n>Deputy Director</span>
            </li>
            <li>
              <span class="name">Jenny Gross</span>
              <span class="function" i18n>Project manager</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p i18n>
      The “Zentrum fir politesch Bildung” (Centre for Citizenship Education, ZpB) is an independent foundation that promotes civic
      engagement by strengthening the understanding of politics, democracy and current societal challenges. Above all it
      seeks to encourage young people to participate in political life and public discourse. Its work is based on three major
      pillars: </p>
    <ul class="zpb-pillars">
      <li i18n>
        Learn: The ZpB develops educational material and continuous trainings on current political and social issues as well as didactics.
        These are aimed at primary and secondary school teachers as well as child care and youth workers.
      </li>
      <li i18n>
        Understand: The ZpB raises awareness about and strengthens the understanding of democratic processes and societal challenges
        through workshops, debates, conferences and expositions. Moreover, it provides books and other information material
        in a specialized library.
      </li>
      <li i18n>
        Participate: The ZpB organizes competitions and other activities to increase the interest of young people in politics. It
        supports institutions in the formal and non-formal education sector in the process of setting up or strengthening
        democratic structures.
      </li>
    </ul>
    <div>
      <a href="https://zpb.lu/" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>Zentrum fir politisch Bildung</ng-container>
      </a>
    </div>
  </section>
  <hr>
  <section class="institution">
    <div class="header">
      <div class="logo smartvote"></div>
      <div class="details">
        <div class="title">
          <h4 i18n>smartvote/Politools</h4>
        </div>
        <div class="members">
          <ul>
            <li>
              <span class="name">Robin Bartlett Rissi</span>
              <span class="function" i18n>Project manager</span>
            </li>
            <li>
              <span class="name">Jan Fivaz</span>
              <span class="function" i18n>Founder</span>
            </li>
            <li>
              <span class="name">Daniel Schwarz</span>
              <span class="function" i18n>Founder</span>
            </li>
            <li>
              <span class="name">Albert Waaijenberg</span>
              <span class="function" i18n>Web design</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p i18n>
      smartvote is a Swiss Voting Advice Application (VAA) developed and run by Politools – an interdisciplinary scientific network
      that operates internet-based projects to promote civic education, political transparency as well as politicial analysis.
      It is located in Bern, Switzerland. Since 2003, smartvote has informed voters in over 200 elections throughout Switzerland
      and Europe.
    </p>
    <div>
      <a href="https://www.smartvote.ch/" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>smartvote</ng-container>
      </a>
    </div>
  </section>
  <hr>
  <section class="institution">
    <div class="header">
      <div class="logo soom-it"></div>
      <div class="details">
        <div class="title">
          <h4 i18n>soom-it</h4>
        </div>
        <div class="members">
          <ul>
            <li>
              <span class="name">Cédric Reginster</span>
            </li>
            <li>
              <span class="name">Johannes Lötscher</span>
            </li>
            <li>
              <span class="name">Matthias Flückiger</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p i18n>A technology company based in Bern, Switzerland, soom-it focuses on the conception, architecture and development
      of sophisticated custom-made software and web applications. As the main technological partner, soom-it supports the
      development of the smartvote platform since 2014.
    </p>
    <div>
      <a href="http://soom-it.ch/" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>soom-it</ng-container>
      </a>
    </div>
  </section>
</svi-page-content>