<div class="party info-item">
  {{candidate.party.name}}
  <ng-container *ngIf="isIncumbent(candidate)">| <ng-container i18n>Incumbent</ng-container>&nbsp;</ng-container>
  <ng-container *ngIf="isElected(candidate)">| <ng-container i18n>Elected</ng-container>&nbsp;</ng-container>
</div>
<div *ngIf="candidate.district" class="info-item">
  <span class="profile-detail-label" i18n>District</span>: {{candidate.district.name}}
</div>
<div *ngIf="candidate.profession" class="info-item">
  <span class="profile-detail-label" i18n>Profession</span>: {{candidate.profession}}
</div>
<div *ngIf="getValue(candidate,'currentPoliticalPosition')" class="info-item">
  <span class="profile-detail-label" i18n>Current Political Position</span>: {{getValue(candidate,"currentPoliticalPosition")}}
</div>
<div *ngIf="candidate.gender" class="info-item">
  <span class="profile-detail-label" i18n>Gender</span><ng-container>:&nbsp;</ng-container>
  <span *ngIf="candidate.gender === 1" i18n>Female</span>
  <span *ngIf="candidate.gender === 2" i18n>Male</span>
  <span *ngIf="candidate.gender === 3" i18n>Other</span>
</div>
<div *ngIf="candidate.yearOfBirth" class="info-item">
  <span class="profile-detail-label" i18n>Year of birth</span>: {{candidate.yearOfBirth}}
</div>