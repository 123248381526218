var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { getResponderType, isCandidate } from '@smartvote/common';
import { DomSanitizer } from '@angular/platform-browser';
import { groupBy } from 'lodash';
import { getQuestionItems } from './question-item';
var DEFAULT_RESPONDER_COLOR = 'f29400';
var ProfileWithVoterComponent = /** @class */ (function () {
    function ProfileWithVoterComponent(_sanitizer, router, localeId) {
        this._sanitizer = _sanitizer;
        this.router = router;
        this.localeId = localeId;
        // Tabs
        this.selectedTabChanged = new EventEmitter();
        this.tabIndex = 0;
        this.responderColor = DEFAULT_RESPONDER_COLOR;
        this.sharePath =
            '/' + this.localeId + this.router.routerState.snapshot.url.replace('matching', 'share');
    }
    Object.defineProperty(ProfileWithVoterComponent.prototype, "voter", {
        get: function () {
            return this._voter;
        },
        set: function (value) {
            if (value) {
                this._voter = __assign({}, value, { smartspider: __assign({}, value.smartspider, { options: { cssClass: 'svi-smartspider-voter' } }) });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileWithVoterComponent.prototype, "questions", {
        set: function (value) {
            if (value) {
                this._questions = groupBy(value, function (q) { return q.category.name; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileWithVoterComponent.prototype, "categories", {
        get: function () {
            return Object.keys(this._questions);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileWithVoterComponent.prototype, "responder", {
        get: function () {
            return this._responder;
        },
        set: function (value) {
            if (!value) {
                return;
            }
            this.responderColor = this.getResponderColor(value);
            this._responder = __assign({}, value, { smartspider: __assign({}, value.smartspider, { options: {
                        fill: "#" + this.responderColor
                    } }) });
            this.displayName = isCandidate(value) ? value.firstname + " " + value.lastname : value.name;
        },
        enumerable: true,
        configurable: true
    });
    ProfileWithVoterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.questionItems = {};
        var prevQuestions = 0;
        this.categories.forEach(function (category) {
            var questions = _this._questions[category];
            _this.questionItems[category] = getQuestionItems(questions, _this.responder.answers, _this.voter && _this.voter.isMe ? _this.voter.answers : [], prevQuestions);
            prevQuestions += questions.length;
        });
    };
    ProfileWithVoterComponent.prototype.ngOnChanges = function () {
        this.sharePath =
            '/' + this.localeId + this.router.routerState.snapshot.url.replace('matching', 'share');
    };
    ProfileWithVoterComponent.prototype.navigateToMethodology = function () {
        this.router.navigate(['/methodology'], { fragment: 'smartspider' });
    };
    ProfileWithVoterComponent.prototype.getTrustedUrl = function (url) {
        return this._sanitizer.bypassSecurityTrustUrl(url);
    };
    ProfileWithVoterComponent.prototype.getValue = function (responder, key, defaultValue) {
        var result = responder.values.find(function (entry) { return entry.key === key; });
        if (!result || !result.value) {
            return defaultValue;
        }
        return result.value;
    };
    ProfileWithVoterComponent.prototype.getResponderColor = function (responder) {
        if (getResponderType(responder) === 'Candidate') {
            return this.getValue(responder.party, 'color', DEFAULT_RESPONDER_COLOR);
        }
        else if (getResponderType(responder) === 'Party') {
            return this.getValue(responder, 'color', DEFAULT_RESPONDER_COLOR);
        }
    };
    return ProfileWithVoterComponent;
}());
export { ProfileWithVoterComponent };
