import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs'
import { tap, switchMap, map } from 'rxjs/operators'
import { Apollo, QueryRef } from 'apollo-angular'
import gql from 'graphql-tag'
import { Question, QuestionConfig, QuestionType, Answer } from '@smartvote/common'

import { AnswerService } from '../core/answer.service'

import { QuestionnaireQuery } from '../../__generated__/types'
const query = require('graphql-tag/loader!./questionnaire.query.graphql')
@Component({
  selector: 'svi-questionnaire-page',
  templateUrl: 'questionnaire.page.html',
  styleUrls: ['questionnaire.page.scss']
})
export class QuestionnairePage implements OnInit {
  questionIndex: number
  hasAnswers
  questions: Observable<Question[]>
  userAnswers: Answer[]
  progress: string

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private answerService: AnswerService
  ) {}

  ngOnInit() {
    this.questions = this.route.queryParamMap.pipe(
      tap(params => {
        this.questionIndex = parseInt(params.get('idx'), 10) || 0
      }),
      switchMap(() => this._getQuery()),
      map(({ data, loading }) => {
        const questions = data.questionnaire.questions
        this.userAnswers = this.answerService.getUserAnswersAsArray(questions)
        this.hasAnswers = this.userAnswers.some(a => a.value >= 0)
        this.progress = `${this.questionIndex + 1}/${questions.length}`
        return questions
      })
    ) as any
  }

  changeAnswer(answer: Answer) {
    this.hasAnswers = this.userAnswers.some(a => a.value >= 0)
    this.answerService.saveAnswer(answer)
  }

  navigate(direction) {
    const newQuestionIndex = this.questionIndex + parseInt(direction, 10)
    this.jumpToQuestion(newQuestionIndex)
  }
  jumpToQuestion(newQuestionIndex) {
    if (this.userAnswers[this.questionIndex].value < -9) {
      this.answerService.saveAnswer(
        Object.assign(this.userAnswers[this.questionIndex], { value: -9 })
      )
    }
    this.router.navigate(['matching', 'questionnaire'], {
      queryParams: { idx: newQuestionIndex },
      replaceUrl: true
    })
  }

  showResults() {
    this.router.navigate(['matching', 'results'])
  }

  private _getQuery() {
    return this.apollo.query<QuestionnaireQuery>({
      query
    })
  }
}
