import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  OnChanges,
  ViewChild,
  ElementRef
} from '@angular/core'
import { Election, District } from '@smartvote/common'
import { FormGroup, FormBuilder } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TrackingService } from '../../core/tracking.service'

export class FilterGroupState {
  election = ''
  district = ''
  responderType: 'Candidate' | 'Party' = 'Candidate'
}

export function stateIsValid(s) {
  return s.election && s.district
}

export function stateIsEqual(a: FilterGroupState, b: FilterGroupState) {
  return (
    a.election === b.election && a.district === b.district && a.responderType === b.responderType
  )
}

@Component({
  selector: 'svi-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnDestroy {
  @Input()
  set disabled(value: boolean) {
    this._disabled = value
    if (value) {
      this.form.disable({ emitEvent: false })
    } else {
      this.form.enable({ emitEvent: false })
    }
  }
  get disabled() {
    return this._disabled
  }
  private _disabled = false
  /* Input `elections` */
  @Input()
  set elections(value: Election[]) {
    if (value) {
      this._elections = value
    } else {
      this._elections = []
    }
    this._setState(new FilterGroupState())
  }
  get elections(): Election[] {
    return this._elections
  }

  /* Input `state` */
  @Input()
  set state(value: FilterGroupState) {
    this._setState(value)
  }
  get state() {
    return this._state
  }
  /* Output `stateChanged` */
  @Output()
  stateChanged = new EventEmitter<FilterGroupState>()

  get districts(): District[] {
    if (!this.election) {
      return []
    }
    return this.election.districts
  }
  get election(): Election | undefined {
    if (!this._state.election) {
      return
    }
    return this.elections.find(e => e.id === this.state.election)
  }
  form: FormGroup
  private _elections: Election[]
  private _state: FilterGroupState
  private _sub: Subscription

  constructor(fb: FormBuilder, private trackingService: TrackingService) {
    this._state = new FilterGroupState()
    this.form = fb.group(this._state)
    this._sub = this.form.valueChanges.subscribe(state => {
      this._state = { ...this.state, ...state }
      this.stateChanged.emit(this._state)
    })
  }

  ngOnDestroy() {
    this._sub.unsubscribe()
  }

  onResponderTypeChange(responderType) {
    this.trackingService.trackEvent('Matching', 'selectResponderType', responderType)
    this.form.patchValue({ responderType })
  }

  trackSelectDistrict(districtId: string) {
    this.trackingService.trackEvent('Matching', 'selectDistrict', 'District' + districtId)
  }

  private _setState(state: FilterGroupState = new FilterGroupState()) {
    this._state = state
    if (this.elections.length === 1) {
      this._state.election = this.elections[0].id
    }
    if (this.elections.length > 1) {
      this.form.controls.election.enable({ emitEvent: false })
    } else {
      this.form.controls.election.disable({ emitEvent: false })
    }
    this.form.patchValue(this._state, { emitEvent: false })
  }
}
