import { Component, OnInit, Input } from '@angular/core'
import { Election, Candidate, Responder } from '@smartvote/common'

@Component({
  selector: 'svi-profile-candidate-details',
  templateUrl: './profile-candidate-details.component.html',
  styleUrls: ['./profile-candidate-details.component.scss']
})
export class ProfileCandidateDetailsComponent {
  @Input() election: Election
  @Input() candidate: Candidate
  constructor() {}

  isIncumbent(candidate: Candidate) {
    return candidate.incumbent
  }

  isElected(candidate: Candidate) {
    return candidate.elected
  }

  getValue(responder: Responder, key: string) {
    const result = responder.values.find(entry => entry.key === key)
    return result ? result.value : null
  }
}
