<svi-navbar></svi-navbar>
<svi-page-title i18n>
  Methodology
</svi-page-title>
<svi-page-content>
  <section id="questionnaire">
    <h3 i18n>Questionnaire design</h3>
    <p i18n>The smartwielen questionnaire has been designed by a combined project team of the University of Luxemburg and the Zentrum
      fir politesch Bildung. In order to guarantee for a comprehensive, significant, valid but also politically neutral questionnaire
      the team consulted also a large number of interest groups and civil society organizations as well as journalists from
      multiple media organizations. Below you find a detailed description of the questionnaire design approach.</p>

    <a href="/assets/pdf/questionnaire_design_fr.pdf" target="_blank">
      <i class="fa fa-angle-right"></i>
      <ng-container i18n>Questionnaire design (PDF, only available in French)</ng-container>
    </a>
  </section>
  <section id="matching">
    <h3 i18n>Matching</h3>
    <p i18n>In order to match the answers/political positions of candidates and voters Smartwielen applies an algorithm based on
      the Euclidean Distance. Below you find a detailed description of the matching-algorithm.</p>
    <a href="/assets/pdf/methods_matching_fr.pdf" target="_blank">
      <i class="fa fa-angle-right"></i>
      <ng-container i18n>Positional matching methodology (PDF, only available in French)</ng-container>
    </a>
  </section>
  <section id="smartspider-smartmap">
    <h3 i18n>smartspider and smartmap</h3>
    <p i18n>In order to calculate positions of candidates and voters on the seven axis of the smartspider graphs and within the two-dimensional
      political space of the smartmap Smartwielen applies common and very simple rating methods. Below you find a detailed
      description of those as well as the complete assignment of questions to the dimensions of the smartspider and the smartmap.</p>
    <div>
      <a href="/assets/pdf/methods_smartspider_smartmap_fr.pdf" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>smartspider and smartmap methodology (PDF, only available in French)</ng-container>
      </a>
    </div>
    <div>
      <a href="/assets/pdf/question_assignment_smartspider_fr.pdf" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>Question assignment smartspider (PDF, only available in French)</ng-container>
      </a>
    </div>
    <div>
      <a href="/assets/pdf/question_assignment_smartmap_fr.pdf" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>Question assignment smartmap (PDF, only available in French)</ng-container>
      </a>
    </div>
  </section>
</svi-page-content>