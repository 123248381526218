<div class="svi-matching-filter-group">
  <form [formGroup]="form">
    <select *ngIf="elections.length > 1" class="form-control" formControlName="election">
      <option value="" i18n>Select Election</option>
      <option *ngFor="let election of elections" [value]="election.id">{{election.name}}</option>
    </select>

    <select class="form-control" formControlName="district" (change)="trackSelectDistrict($event.target.value)">
      <option value="" i18n>Select District</option>
      <option *ngFor="let district of districts" [value]="district.id">{{district.name}}</option>
    </select>
  </form>
  <div class="button-group">
    <button [disabled]="disabled" [ngClass]="{'active': state.responderType === 'Candidate'}" (click)="onResponderTypeChange('Candidate')"
      i18n>Show Candidates</button>
    <button [disabled]="disabled" [ngClass]="{'active': state.responderType === 'Party'}" (click)="onResponderTypeChange('Party')"
      i18n>Show Parties</button>
  </div>

</div>