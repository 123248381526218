/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-and-privacy.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../../shared/navbar/navbar.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../core/tokens";
import * as i7 from "../../shared/page-title/page-title.component.ngfactory";
import * as i8 from "../../shared/page-title/page-title.component";
import * as i9 from "../../shared/page-content/page-content.component.ngfactory";
import * as i10 from "../../shared/page-content/page-content.component";
import * as i11 from "./terms-and-privacy.page";
import * as i12 from "../../core/tracking.service";
var styles_TermsAndPrivacyPage = [i0.styles];
var RenderType_TermsAndPrivacyPage = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsAndPrivacyPage, data: {} });
export { RenderType_TermsAndPrivacyPage as RenderType_TermsAndPrivacyPage };
export function View_TermsAndPrivacyPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavbarComponent, [i4.Location, i5.Router, i6.LocalStorage], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "svi-page-title", [], null, null, null, i7.View_PageTitleComponent_0, i7.RenderType_PageTitleComponent)), i1.ɵdid(3, 49152, null, 0, i8.PageTitleComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Conditiounen a Dateschutz"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "svi-page-content", [], null, null, null, i9.View_PageContentComponent_0, i9.RenderType_PageContentComponent)), i1.ɵdid(6, 49152, null, 0, i10.PageContentComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Smartwielen benotzt Cookien an der lokaler Sp\u00E4icherung vun \u00C4rem Browser fir d'Performance an d'Notzerfr\u00EBndlechkeet ze verbesseren. Smartwielen br\u00E9ngt d'Veraarbechtung vu pers\u00E9inlechen Donn\u00E9e\u00EBn, och sensibelen Daten (z.B. politesch Positioune vu Kandidaten/-innen oder Benotzer/-innen) mat sech, dofir huet den Dateschutz no der Allgemenger EU-Dateschutz-Reglementatioun (GDPR) Priorit\u00E9it."])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["D'Dokument hei dr\u00EBnner huet eng detaill\u00E9iert Beschreiwung vun eiser Dateschutzpolitik a f\u00E9iert och d\u00E9i allgemeng Konditioune fir d'Benotzer/-innen op.\nMir encourag\u00E9iere jiddereen d\u00EBst Dokument opmierksam ze liesen."])), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "a", [["href", "/assets/pdf/privacy_policy_users_fr.pdf"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.trackViewTerms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-angle-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dateschutzpolitik a Konditioune vun der Benotzung fir d'Notzer/-innen (PDF, n\u00EBmmen op Frans\u00E9isch)"]))], null, null); }
export function View_TermsAndPrivacyPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-terms-and-privacy", [], null, null, null, View_TermsAndPrivacyPage_0, RenderType_TermsAndPrivacyPage)), i1.ɵdid(1, 4243456, null, 0, i11.TermsAndPrivacyPage, [i1.PLATFORM_ID, i12.TrackingService], null, null)], null, null); }
var TermsAndPrivacyPageNgFactory = i1.ɵccf("svi-terms-and-privacy", i11.TermsAndPrivacyPage, View_TermsAndPrivacyPage_Host_0, {}, {}, []);
export { TermsAndPrivacyPageNgFactory as TermsAndPrivacyPageNgFactory };
