
    <svi-policy-layover
    (hasAccepted)="startTracking()"
    linkText="Cookie/privacy policy and terms of use for users (PDF, only available in French)"
    text="Smartwielen uses cookies and the local storage of your web browser to improve performance and usability.
    Using smartwielen involves processing of sensitive data (e.g., political positions of candidates or users),
    therefore data and privacy protection according the EU General Data Protection Regulation (GDPR) is a priority.
    The document below contains a detailed description of our cookie and privacy policy as well as the general terms of use for users.
    We strongly encourage everyone to carefully read this document."
    link="../assets/pdf/privacy_policy_users_fr.pdf"
    i18n-text i18n-linkText></svi-policy-layover>
    <svi-layout>
    </svi-layout>
  