import { Injectable, Inject, Component } from '@angular/core'
import * as uuid from 'uuid'

import { environment } from '../../../environments/environment.prod'
import { HttpClient } from '@angular/common/http'

export interface SendEmailOptions {
  from?: string // Set in here
  replyTo: string
  to: string
  template: string
  context: any
}

export interface SendEmailResponse {
  success: boolean
  error: string
}


@Injectable()
export class EmailService {
  constructor(private http: HttpClient) {}
  sendEmail(options: SendEmailOptions): Promise<SendEmailResponse> {
    return this.http
      .post<SendEmailResponse>(environment.emailerUrl, {
        ...options,
        from: environment.emailerFromAddress
      })
      .toPromise()
  }
}
