/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "../../shared/sharing/sharing.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "../footer/footer.component.ngfactory";
import * as i8 from "../footer/footer.component";
import * as i9 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "svi-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svi-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.HeaderComponent, [i4.SharingService, i5.Location, i6.ActivatedRoute, i1.LOCALE_ID], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "sponsors"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "content partner-logo__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "a", [["class", "partner-logo uni-lux"], ["href", "https://wwwen.uni.lu/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "a", [["class", "partner-logo zpb"], ["href", "https://zpb.lu/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "a", [["class", "partner-logo smartvote"], ["href", "https://www.smartvote.ch/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "svi-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(12, 49152, null, 0, i8.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 114688, null, 0, i9.LayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("svi-layout", i9.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
