var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { SharingInfoDirective } from '../../shared/sharing/sharing-info.directive';
import { SharingService } from '../../shared/sharing/sharing.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(sharingService, location, route, localeId) {
        this.sharingService = sharingService;
        this.location = location;
        this.route = route;
        this.localeId = localeId;
        this.languages = [
            { labelCode: 'lu', code: 'lb', lang: 'lëtzebuergesch' },
            { labelCode: 'de', code: 'de', lang: 'deutsch' },
            { labelCode: 'fr', code: 'fr', lang: 'français' },
            { labelCode: 'en', code: 'en', lang: 'english' }
        ];
    }
    HeaderComponent.prototype.setLanguage = function (language) {
        var path = this.location.path(true);
        var idx = path.indexOf('?');
        // remove query part
        if (idx >= 0) {
            path = path.substr(0, idx);
        }
        var params = __assign({}, this.route.snapshot.queryParams, { locale: language + "_CH" });
        var newUrl = "/" + language + path + "?" + new HttpParams({ fromObject: params }).toString();
        window.location.replace(newUrl);
    };
    HeaderComponent.prototype.isLanguageSelected = function (language) {
        return language === this.localeId;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
