/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./core/policy-layover/policy-layover.component.ngfactory";
import * as i2 from "./core/policy-layover/policy-layover.component";
import * as i3 from "@angular/common";
import * as i4 from "./core/tokens";
import * as i5 from "./core/layout/layout.component.ngfactory";
import * as i6 from "./core/layout/layout.component";
import * as i7 from "./app.component";
import * as i8 from "./core/tracking.service";
var styles_AppComponent = [""];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-policy-layover", [["link", "../assets/pdf/privacy_policy_users_fr.pdf"], ["linkText", "Dateschutzpolitik a Konditioune vun der Benotzung fir d'Notzer/-innen (PDF, n\u00EBmmen op Frans\u00E9isch)"], ["text", "Smartwielen benotzt Cookien an der lokaler Sp\u00E4icherung vun \u00C4rem Browser fir d'Performance an d'Notzerfr\u00EBndlechkeet ze verbesseren. Smartwielen br\u00E9ngt d'Veraarbechtung vu pers\u00E9inlechen Donn\u00E9e\u00EBn, och sensibelen Daten (z.B. politesch Positioune vu Kandidaten/-innen oder Benotzer/-innen) mat sech, dofir huet den Dateschutz no der Allgemenger EU-Dateschutz-Reglementatioun (GDPR) Priorit\u00E9it. D'Dokument hei dr\u00EBnner g\u00EBtt eng detaill\u00E9iert Beschreiwung vun eiser Dateschutzpolitik a f\u00E9iert och d\u00E9i allgemeng Konditioune fir d'Benotzer/-innen op.\nMir encourag\u00E9iere jiddereen d\u00EBst Dokument opmierksam ze liesen."]], null, [[null, "hasAccepted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hasAccepted" === en)) {
        var pd_0 = (_co.startTracking() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PolicyLayoverComponent_0, i1.RenderType_PolicyLayoverComponent)), i0.ɵdid(1, 4243456, null, 0, i2.PolicyLayoverComponent, [i3.Location, i0.PLATFORM_ID, i4.LocalStorage], { text: [0, "text"], linkText: [1, "linkText"], link: [2, "link"] }, { hasAccepted: "hasAccepted" }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "svi-layout", [], null, null, null, i5.View_LayoutComponent_0, i5.RenderType_LayoutComponent)), i0.ɵdid(3, 114688, null, 0, i6.LayoutComponent, [], null, null)], function (_ck, _v) { var currVal_0 = "Smartwielen benotzt Cookien an der lokaler Sp\u00E4icherung vun \u00C4rem Browser fir d'Performance an d'Notzerfr\u00EBndlechkeet ze verbesseren. Smartwielen br\u00E9ngt d'Veraarbechtung vu pers\u00E9inlechen Donn\u00E9e\u00EBn, och sensibelen Daten (z.B. politesch Positioune vu Kandidaten/-innen oder Benotzer/-innen) mat sech, dofir huet den Dateschutz no der Allgemenger EU-Dateschutz-Reglementatioun (GDPR) Priorit\u00E9it. D'Dokument hei dr\u00EBnner g\u00EBtt eng detaill\u00E9iert Beschreiwung vun eiser Dateschutzpolitik a f\u00E9iert och d\u00E9i allgemeng Konditioune fir d'Benotzer/-innen op.\nMir encourag\u00E9iere jiddereen d\u00EBst Dokument opmierksam ze liesen."; var currVal_1 = "Dateschutzpolitik a Konditioune vun der Benotzung fir d'Notzer/-innen (PDF, n\u00EBmmen op Frans\u00E9isch)"; var currVal_2 = "../assets/pdf/privacy_policy_users_fr.pdf"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i7.AppComponent, [i8.TrackingService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("svi-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
